import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Params = Record<string, string | string[]>;

type Setter<T extends Params> = (values: Partial<T>, options?: { push?: boolean }) => void;

export const getRouteParams = <T extends Params>(initial: T, search: string): T => {
  const urlParams = new URLSearchParams(search);
  const keys = Object.keys(initial);
  return keys.reduce<Params>((acc, key) => {
    const stringValue = urlParams.get(key);
    const isArray = Array.isArray(initial[key]);
    const value = isArray ? stringValue && urlParams.getAll(key) : stringValue;

    acc[key] = value === null ? (initial[key] ?? '') : value;
    return acc;
  }, {}) as T;
};

const useRouteState = <T extends Params>(initial: T): [T, Setter<T>] => {
  const history = useHistory();
  const { search } = useLocation();

  const params = React.useMemo(() => getRouteParams(initial, search), [initial, search]);

  const setState: Setter<T> = React.useCallback(
    (values, options) => {
      const qs = new URLSearchParams(history.location.search);
      Object.keys(values).forEach((key) => {
        const newValue = values[key];
        if (Array.isArray(newValue) && newValue.length > 0) {
          qs.delete(key);
          newValue.forEach((val) => qs.append(key, val));
        } else if (Array.isArray(newValue)) {
          qs.set(key, '');
        } else if (newValue) {
          qs.set(key, newValue ?? '');
        }
      });
      const uri = `${history.location.pathname}?${qs.toString()}`;
      const redirect = options?.push ? history.push : history.replace;
      redirect(uri);
    },
    [history]
  );

  return React.useMemo(() => [params, setState], [params, setState]);
};

export default useRouteState;
