import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import current from '@config';

import { OverlayLoader, PageError, Message, SuspenseWrapper } from 'react-components';
import { createEnvironment } from 'utils/src/RelayEnvironment';
import { graphql, RelayEnvironmentProvider, useLazyLoadQuery } from 'react-relay/hooks';
import { DialogProvider } from 'react-components/src/components/dialog';
import { ToastProvider } from 'react-components/src/components/toast';
import { LogCategories, errorHandler } from 'utils/src/Logs';
import { contactSupportMessage } from 'utils/src/Support';
import { ErrorBoundary } from 'react-error-boundary';

import projectPackage from '../../package.json';
import Providers from './providers';
import Routes from '../pages/routes';

import { useCompanyPicker } from '../hooks/use-company-picker';
import { useAuthentication } from '../data/auth/transition';

import type { appEmployerCheckerQuery as Query } from './__generated__/appEmployerCheckerQuery.graphql';

const { MessageTypes } = Message;
const { version } = projectPackage;

const appHeader = (() => {
  if (current.isClaims) return 'claims';
  if (current.isOpsDash) return 'ops';

  return 'admin';
})();

const query = graphql`
  query appEmployerCheckerQuery($platform: String!) {
    res: availableEmployers(platform: $platform) {
      apiID
      name
    }
  }
`;

const EmployerChecker = () => {
  const [employerId, setEmployerId] = useCompanyPicker();
  const data = useLazyLoadQuery<Query>(query, { platform: current.isOpsDash ? 'opsdash' : 'admindash' });

  // @notes
  //  we've added this logic to make sure that `employerId` is always a valid employer id
  //  what's happening here is that we are checking if:
  //    - `employerId` is not set OR
  //    - `employerId` is not in the list of available employers (this could happen if the
  //       user has some cached version of the `employerId` in local storage)
  useEffect(() => {
    if (!employerId || (data.res || []).every(({ apiID }) => apiID !== employerId)) {
      const [firstEmployer] = data.res;

      if (firstEmployer) {
        setEmployerId?.(firstEmployer.apiID);
      }
    }
  }, [employerId, data.res, setEmployerId]);

  return null;
};

const EmployerCheckerWrapper = () => {
  const [, setEmployerId] = useCompanyPicker();

  return (
    <ErrorBoundary
      FallbackComponent={() => null}
      onError={async () => {
        await setEmployerId?.('', { source: 'route' });
        await setEmployerId?.('', { source: 'localstorage' });
      }}
    >
      <EmployerChecker />
    </ErrorBoundary>
  );
};

const Main = () => {
  const { user, getAccessToken } = useAuthentication();
  const [employerId] = useCompanyPicker();

  const relayEnvironment = React.useMemo(
    () =>
      createEnvironment({
        graphQLUrl: current.api.graphql,
        getAccessTokenSilently: getAccessToken,
        email: user?.email,
        extraHeaders: {
          'x-benepass-platform': 'web',
          'x-benepass-client': appHeader,
          'x-benepass-version': process.env.npm_package_version || version,
          ...(employerId ? { 'x-benepass-employerId': employerId } : {}),
        },
      }),
    [employerId, getAccessToken, user?.email]
  );

  useEffect(() => {
    Sentry.setUser(user);
  }, [user]);

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      <SuspenseWrapper
        loader={<OverlayLoader message="Loading your data..." />}
        errorFallback={
          <PageError title="Server Unreachable">
            <Message type={MessageTypes.ERROR}>Something is wrong on Benepass Servers. {contactSupportMessage}</Message>
          </PageError>
        }
        onError={errorHandler(LogCategories.UNKNOWN_ERROR)}
      >
        <EmployerCheckerWrapper />
        <Routes />
        <ToastProvider />
        <DialogProvider />
      </SuspenseWrapper>
    </RelayEnvironmentProvider>
  );
};

const App = () => (
  <Providers>
    <Main />
  </Providers>
);

export default App;
